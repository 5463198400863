@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-2xl font-semibold text-gray-900 mt-4;
    }
    h2 {
        @apply text-xl font-semibold text-gray-900 mt-4;
    }
    h3 {
        @apply text-lg font-semibold text-gray-900 mt-4;
    }
    a {
        @apply text-green-gmkhb;
    }
    a:hover {
        @apply underline;
    }

    ul {
        display: block;
        list-style-type: disc;
        margin-top: 1em;
        margin-bottom: 1 em;
        margin-left: 0;
        margin-right: 0;
        padding-left: 40px; 
    }
    li {
        display: list-item;
    }
}